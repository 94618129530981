var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"mt-0 pt-0",attrs:{"fluid":""}},[_c('v-card',{staticClass:"ma-1 pa-3 rounded-xl mb-3"},[_c('v-card-title',{attrs:{"primary-title":""}},[_c('div',{staticClass:"headline primary--text"},[_vm._v("Recepção")])]),_c('v-card',{staticClass:"ma-1 pa-3 rounded-xl mb-3"},[_c('v-card-title',{attrs:{"primary-title":""}},[_c('div',{staticClass:"headline primary--text"},[_vm._v("Matricular")])]),_c('v-card-text',[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',[_c('search-entidade-component',{attrs:{"propEntityType":['prospex', 'aluno']},model:{value:(_vm.entidadeSelected),callback:function ($$v) {_vm.entidadeSelected=$$v},expression:"entidadeSelected"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){return _vm.iniciarMatricula()}}},[_vm._v("Iniciar processo de matrícula")])],1)],1),_c('v-card',{staticClass:"ma-1 pa-3 rounded-xl mb-3"},[_c('v-card-title',{attrs:{"primary-title":""}},[_c('div',{staticClass:"headline primary--text"},[_vm._v("Agenda do dia de hoje")])]),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"auto"}},[_c('date-field-component',{staticStyle:{"position":"relative","top":"-10px"},attrs:{"propTitle":"Data da agenda"},model:{value:(_vm.agendaData),callback:function ($$v) {_vm.agendaData=$$v},expression:"agendaData"}})],1),_c('v-col',[_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.atualizaDados()}}},[_vm._v("Buscar")])],1)],1),_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',[_c('v-data-table',{attrs:{"dense":"","search":_vm.search,"headers":_vm.cuponsAgendadosHeaders,"items":_vm.cuponsAgendados,"footer-props":{ itemsPerPageOptions: [-1] },"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDateTime")(item.created_at))+" ")]}},{key:"item.atendente",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.atendente)+" ")]}},{key:"item.nome",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.nome)+" ")]}},{key:"item.telefone",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.telefone)+" ")]}},{key:"item.agendamento",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDateTimeWithoutConvert")(item.agendamento))+" ")]}},{key:"item.parceria_nome",fn:function(ref){
var item = ref.item;
return [_c('open-new-tab-component',{attrs:{"propObjectLabel":("" + (item.parceria_nome)),"propObjectId":item.parceria_id,"propToolName":"Parcerias"}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"primary",attrs:{"x-small":""},on:{"click":function($event){return _vm.prospectar(item)}}},[_vm._v(" Iniciar processo prospecção ")])]}}],null,true)})],1)],1)],1)],1),_c('v-card-actions')],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }