import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import { parceriaCupomHistoricoStore } from '@/store';
import ShowPopup from '@/components/ShowPopup.vue';
import SearchEntidadeComponent from '@/components/SearchEntidadeComponent.vue';
import OpenNewTabComponent from '@/components/OpenNewTabComponent.vue';
import DateFieldComponent from '@/components/DateFieldComponent.vue';
let Recepcao = class Recepcao extends Vue {
    constructor() {
        super(...arguments);
        this.entidadeSelected = null;
        this.entidadeOptions = [];
        this.searchEntidade = null;
        this.agendamento_dia = null;
        this.agendamento_hora = null;
        this.agendaData = null;
        this.cuponsAgendados = [];
        this.search = '';
        this.cuponsAgendadosHeaders = [
            { text: 'Data da ligação', value: 'created_at' },
            { text: 'Agendado para', value: 'agendamento' },
            { text: 'Agendado por', value: 'atendente' },
            { text: 'Nome', value: 'nome' },
            { text: 'Telefone', value: 'telefone' },
            { text: 'Parceria', value: 'parceria_nome' },
            { text: '', value: 'actions' },
        ];
    }
    prospectar(item) {
        this.$router.push({
            name: 'main-prospexs-create',
            params: { parceria_cupom_id: item.parceria_cupom_id },
        });
    }
    iniciarMatricula() {
        if (this.entidadeSelected.tipo === 'Aluno') {
            this.$router.push({
                name: 'main-alunos-edit',
                params: { id: this.entidadeSelected.id },
            });
        }
        else {
            this.$router.push({
                name: 'main-alunos-matricula',
                params: { prospex_id: this.entidadeSelected.id },
            });
        }
    }
    async atualizaDados() {
        this.cuponsAgendados =
            await parceriaCupomHistoricoStore.getParceriaCuponsAgendados(this.agendaData);
    }
    async carregaDados() {
        const date = new Date();
        this.agendaData = new Date(date.getFullYear(), date.getMonth(), date.getDate());
        this.agendaData = this.agendaData.toISOString().split('T')[0];
        this.cuponsAgendados =
            await parceriaCupomHistoricoStore.getParceriaCuponsAgendados(this.agendaData);
    }
    async mounted() {
        await this.carregaDados();
    }
};
Recepcao = __decorate([
    Component({
        components: {
            ShowPopup,
            SearchEntidadeComponent,
            OpenNewTabComponent,
            DateFieldComponent,
        },
    })
], Recepcao);
export default Recepcao;
